@page {
    size: A4;
    margin: 2cm 1.5cm;
}


@media print {
    html, body {
        //width: 210mm !important;
        height: auto !important;
        //min-height: 297mm !important;

        page-break-after: avoid;
    }


    body {
        overflow: visible !important;

        position: relative !important;

        padding: 0 !important;
        margin: 0 !important;

        border-bottom: 2mm solid $SECONDARY_LIGHT_COLOR;


        a {
            &[href] {
                &:after {
                    display: none !important;
                }
            }
        }


        header {
            .meta-header {
                display: none !important;
            }


            .site-header {
                width: 100% !important;


                .navbar-toggle {
                    display: none !important;
                }


                .logo-wrapper {
                    img {
                        height: auto !important;
                    }
                }
            }


            .mainnav-header {
                display: none !important;
            }
        }


        main {
            width: auto !important;

            padding-top: 3cm !important;
            padding-bottom: 2cm !important;
            margin: 0 !important;

            page-break-after: avoid;


            &:after {
                content: "dolle.de" !important;

                float: right !important;

                @include secondary-headline-config();
                color: $MAIN_COLOR;
                line-height: 2cm;
            }

            &.content-uk {
                &:after {
                    content: "dolle-uk.co.uk" !important;
                }
            }

            &.content-eu {
                &:after {
                    content: "dolle.eu" !important;
                }
            }

            &.content-dk {
                &:after {
                    content: "dolle.dk" !important;
                }
            }

            &.content-se {
                &:after {
                    content: "dolle.se" !important;
                }
            }

            &.content-pl {
                &:after {
                    content: "dolle.com.pl" !important;
                }
            }

            &.content-no {
                &:after {
                    content: "dolle.no" !important;
                }
            }

            &.content-fi {
                &:after {
                    content: "dolle.fi" !important;
                }
            }

            .module-intro_image_small + .module {
                margin-top: 0;
            }


            .module-breadcrumb,
            .page.messages,
            .header-minicart-wrapper {
                display: none !important;
            }


            > .columns {
                display: block !important;

                > .column.main {
                    display: block !important;
                }
            }


            .product-subinfos, .print-link-col,
            button,
            .btn {
                display: none !important;
            }


            .module-backtotop,
            .product-add-form {
                display: none !important;
            }


            .column.main {
                position: relative;
            }


            .product-info-main {
                float: none !important;

                width: 66.6% !important;
                min-height: 10cm !important;

                margin-left: 33.3% !important;
            }


            .product {
                &.media {
                    position: absolute !important;
                    top: 0 !important;
                    left: 0 !important;

                    width: 33.3% !important;


                    .gallery-placeholder {
                        .fotorama {
                            .fotorama__wrap {
                                .fotorama__stage {
                                    width: 100% !important;
                                    //height: auto !important;

                                    margin: 0 0 1cm 0;

                                    line-height: normal !important;


                                    .fotorama__arr {
                                        display: none !important;
                                    }


                                    .fotorama__stage__shaft {
                                        //height: auto !important;

                                        position: relative !important;
                                        bottom: auto !important;

                                        border: none !important;


                                        .fotorama__stage__frame {
                                            display: none;
                                            overflow: visible;

                                            position: relative !important;

                                            //height: auto !important;

                                            border: none !important;

                                            text-align: left;

                                            &:before {
                                                display: none;
                                            }

                                            &:first-child {
                                                display: block;
                                            }

                                            .fotorama__img {
                                                left: auto;
                                                top: auto;
                                                transform: none;
                                                max-width: 85% !important;
                                            }
                                        }
                                    }
                                }


                                .fotorama__nav-wrap {
                                    display: none !important;
                                }
                            }
                        }
                    }
                }


                &.info.detailed {
                    float: none !important;

                    margin-top: 3cm;


                    .product.data.items {
                        > .item {
                            &.title {
                                margin-left: 0 !important;

                                page-break-inside: avoid;
                                page-break-after: avoid;


                                a.data.switch {
                                    border-top: none !important;

                                    @include font-DIN-next-regular(0.5cm, 1.25);
                                    text-transform: uppercase !important;
                                    text-decoration: underline !important;

                                    &:after {
                                        display: none;
                                    }
                                }

                                &.active {
                                    a.data.switch {
                                        border-bottom: none !important;

                                        @include font-DIN-next-regular(0.5cm, 1.25);
                                        text-transform: uppercase !important;
                                        text-decoration: underline !important;

                                        background-color: transparent !important;
                                    }
                                }


                                &[id='tab-label-catalog.product.related'] {
                                    display: none !important;
                                }
                            }


                            &.content {
                                display: block !important;
                                visibility: visible !important;
                                overflow: visible !important;

                                height: auto !important;

                                padding: 0 !important;
                                border: none !important;
                                margin-bottom: 2cm;

                                page-break-before: avoid;


                                &[id='catalog.product.detail.tabs.detail'] {
                                    .product-detail-container {
                                        ul {
                                            column-count: 1;
                                        }
                                    }


                                    .media-headline {
                                        display: none !important;
                                    }


                                    ol.media {
                                        display: none !important;
                                    }
                                }


                                &[id='catalog.product.detail.tabs.technical_data'] {
                                    position: relative !important;


                                    .product-technical-data-container {
                                        > .left {
                                            float: none !important;

                                            position: absolute !important;
                                            top: 0 !important;
                                            left: 0 !important;

                                            width: 33.3% !important;


                                            img {
                                                width: 100% !important;
                                            }
                                        }

                                        > .right {
                                            float: none !important;

                                            width: 66.6% !important;
                                            min-height: 5cm !important;

                                            margin-left: 33.3% !important;


                                            .product-attributes-headline {
                                                display: none !important;
                                            }


                                            .product-attributes {
                                                @include clearfix();


                                                dt.divider {
                                                    clear: left;
                                                }

                                                dd.divider {
                                                    clear: right;
                                                }
                                            }
                                        }
                                    }
                                }


                                &[id='mageworx_product_attachments'] {
                                    .product-attachment-container {
                                        .downloads-item {
                                            float: none !important;

                                            width: 100% !important;

                                            @include clearfix();
                                        }
                                    }
                                }


                                &[id='catalog.product.related'] {
                                    display: none !important;
                                }
                            }
                        }
                    }
                }
            }


            .block.upsell {
                .products-upsell {
                    .product-item {
                        .product-item-info {
                            border: none;


                            .product-item-photo {
                                &[href]:after {
                                    content: none !important;
                                }
                            }


                            .product-image-container {
                                border: none;
                            }


                            .product-item-details {
                                .product-item-link {
                                    &[href]:after {
                                        content: none !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }


        footer {
            display: none !important;
        }


        > .modals-wrapper {
            display: none !important;
        }


        &.catalog-product-view {
            #catalog.product.related,
            #tab-label-catalog.product.related,
            .block.upsell,
            .module-teaser_container {
                display: none !important;
            }
        }
    }

    body#dolle-shelving {
        main {
            &:after {
                content: "dolle-wohnregale.de" !important;
            }
        }
    }
}
