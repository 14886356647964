$SECONDARY_COLOR: #941017;
$SECONDARY_LIGHT_COLOR: #d10019;
$BRAND_PRIMARY: #d10019;
$BRAND_PRIMARY_DARKEN: #941017;
$MINICART_TOP_POS_MD: 93px;@import 'bootstrap/bootstrap.mod';

@import 'base/defines';

@import 'lib/slick';

@import 'mixins/utils';
@import 'mixins/bootstrap-breakpoints';
@import 'mixins/text-image-replace';

@import 'base/fonts';
@import 'base/tags';
@import 'base/buttons';
@import 'base/form';
@import 'base/print';

@import 'layout/general';

@import 'module/module-search';
@import 'module/module-breadcrumb';
@import 'module/module-navigation';
@import 'module/module-intro';
@import 'module/module-biu-gallery';
@import 'module/module-biu-configurator';
@import 'module/module-accordion';
@import 'module/module-product-teaser';
@import 'module/module-teaser';
@import 'module/module-contact-form';
@import 'module/module-static-map';
@import 'module/module-video';
@import 'module/module-model-search';
@import 'module/module-model-teaser';
@import 'module/module-lightbox';
@import 'module/module-retailer';
@import 'module/module-responsive-image';
@import 'module/module-background-image';
@import 'module/module-colored-textbox';
@import 'module/module-structure';
@import 'module/module-empty-space';